import React, { Component } from 'react'
import { navigate } from 'gatsby'
import SEO from './seo'

import './projects.less'

class ProjectHeader extends Component {
  goTo = (e, link, white = false) => {
    e.preventDefault()
    if (white) {
      document.body.classList.add('cover', 'white')
    } else {
      document.body.classList.add('cover')
    }
    setTimeout(() => {
      navigate(link)
    }, 700)
  }

  reveal_page = () => {
    document.body.classList.add('remove')
    setTimeout(() => {
      document.body.classList.remove('cover', 'remove', 'white')
    }, 800)
    setTimeout(() => {
      this.setState({ content: 'visible' })
    }, 1000)
    document.body.classList.remove('scroll-stop')
  }

  render() {
    const {
      title,
      year,
      index,
      image,
      total,
      overview,
      overview2,
      expertise,
      url,
      next,
      prev,
    } = this.props

    return (
      <section className="single-project">
        <SEO title={title} description={overview} image={image} />
        <div className="project-hero">
          <div className="uk-grid uk-grid-collapse uk-child-width-1-2@m">
            <div className="text uk-text-center">
              <h1>{title}</h1>
              {/*<p>{year}</p>*/}
              <p className="project-pagination uk-visible@m">
                <span className="number-container">
                  <span className="active">{index}</span>
                </span>{' '}
                &ndash; <span className="total">{total}</span>
                <span
                  className="arrows prev"
                  onClick={(e) => this.goTo(e, prev)}
                ></span>
                <span
                  className="arrows next"
                  onClick={(e) => this.goTo(e, next)}
                ></span>
              </p>
            </div>
            <div className="image">
              <img src={image} alt={title} onLoad={this.reveal_page} />
            </div>
          </div>
        </div>

        <div className="project-intro">
          <div className="uk-container">
            <div className="uk-grid uk-grid-collapse">
              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s uk-margin-auto">
                <h2>Overview</h2>
                <p>{overview}</p>
                {overview2 && <p className="uk-margin-top">{overview2}</p>}
                <h3>Expertise</h3>
                <div className="uk-flex">
                  <p>{expertise}</p>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    View Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export { ProjectHeader }
