import React, { Component } from 'react'
import { navigate } from 'gatsby'

export default class ProjectCTA extends Component {

    nextProject = (e) => {
        e.preventDefault();
        document.body.classList.add('cover');
        setTimeout(() => {
            navigate("/featured-projects/" + this.props.link)
        }, 700);
    }

    render() {
        return (
            <section className="bottom-cta projects">
                <div>
                    <span className="link" onClick={this.nextProject}>
                        Next Project
					<span>{this.props.title}</span>
                    </span>
                </div>
            </section>
        )
    }
}
