import React from 'react'
import Layout from '../../components/layout'
import { ProjectHeader } from '../../components/projects'
import ProjectCTA from '../../components/project-cta'
import Image from '../../images/projects/project-main-edi.jpg'

import './everyday-icing.less'

import AccountDetails from '../../images/projects/edi/account-details.png'
import Email from '../../images/projects/edi/confirm-email.png'
import CreditCard from '../../images/projects/edi/credit-card.png'
import GetStarted from '../../images/projects/edi/get-started.png'
import Home from '../../images/projects/edi/home.jpg'
import Library from '../../images/projects/edi/library.png'
import SelectPlan from '../../images/projects/edi/select-plan.png'
import Stories from '../../images/projects/edi/stories.png'

class EDI extends React.Component {
  render() {
    return (
      <Layout>
        <ProjectHeader
          title="Everyday Icing"
          year="2018"
          index="3"
          image={Image}
          total="3"
          overview="Everyday Icing is a digital collection of stories that allow young people to explore, imagine, and make-believe in a world where technology is on the rise. It is a place where technology facilitates connection and creativity is limitless."
          expertise="Design and Development"
          url="https://everydayicing.com"
          prev="/featured-projects/eras"
          next="/featured-projects/workplace-one"
        />

        <section className="project-single-body edi" id="start_dark_menu">
          <div className="uk-container">
            <div className="uk-grid">
              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s margin uk-margin-auto">
                <h2>Designing an intuitive storytelling platform.</h2>
                <p>
                  We worked with the Everyday Icing team to create a library
                  that would be easily accessible by busy parents on the basis
                  of a monthly subscription. Our mission was to establish trust
                  while also providing an intuitive experience anchored in
                  whimsical storytelling.
                  <br />
                  <br />
                  By designing a clean interface with friendly colors and
                  whimsical visual elements, we aimed to delight curious
                  visitors and reward busy parents with content they don’t need
                  to think twice about.
                </p>
              </div>

              <div className="uk-width-2-3@s">
                <img src={Library} alt="" className="left-image shadow" />
              </div>

              <div className="uk-grid">
                <div className="uk-width-1-3@s"></div>
                <div className="uk-width-2-3@s">
                  <img
                    src={AccountDetails}
                    alt=""
                    className="right-image shadow"
                  />
                </div>
              </div>

              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s uk-margin-auto margin">
                <h2>A simple, yet trustworthy onboarding process.</h2>
                <p>
                  The first tool we built was a dependable onboarding process
                  that allows parents to have instant access to the library. We
                  included the use of a confirmation code received via email in
                  order to reaffirm the site’s security.
                  <br />
                  <br />
                  From there, parents are able to browse and select stories in
                  5, 10, and 15 minute increments. We also implemented an audio
                  section for parents who wanted to experience the storytelling
                  journey alongside their child.
                </p>
              </div>
            </div>

            <div className="uk-grid uk-grid-collapse img-set-container">
              <div className="uk-width-1-4@l uk-width-1-2@s">
                <img
                  src={GetStarted}
                  alt=""
                  className="shadow uk-margin set first"
                />
              </div>
              <div className="uk-width-1-4@l uk-width-1-2@s uk-text-right">
                <img
                  src={Email}
                  alt=""
                  className="shadow uk-margin set second"
                />
              </div>
              <div className="uk-width-1-4@l uk-width-1-2@s">
                <img
                  src={SelectPlan}
                  alt=""
                  className="shadow uk-margin set third"
                />
              </div>
              <div className="uk-width-1-4@l uk-width-1-2@s uk-text-right">
                <img
                  src={CreditCard}
                  alt=""
                  className="shadow uk-margin set fourth"
                />
              </div>
            </div>

            <div className="uk-grid">
              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s margin uk-margin-auto">
                <h2>Changing up the ordinary.</h2>
                <p>
                  Throughout the entire library, we implemented animations that
                  appear to playfully jump around and pop off the screen. These
                  interactions are designed not only to appeal to the youngest
                  of users, but to put a smile on their parent’s faces as well.
                </p>
              </div>

              <div className="uk-width-5-6@l uk-margin-auto">
                <div className="uk-grid uk-grid-medium">
                  <div className="uk-width-1-2@s">
                    <img
                      src={Home}
                      alt=""
                      className="shadow image-margin-bottom"
                    />
                  </div>
                  <div className="uk-width-1-2@s">
                    <img
                      src={Stories}
                      alt=""
                      className="shadow image-margin-bottom"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ProjectCTA link="workplace-one" title="Workplace One" />
      </Layout>
    )
  }
}

export default EDI
